<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import StatCard from "@/components/dashboard/StatCard.vue";
import API from "@/plugins/axios-method";
import LineChart from "../../components/charts/line-chart.vue";
import ExternalStatCard from "@/components/ExternalStatCard.vue";
import DataLoader from "@/components/data-loader.vue";
import PieChart from "@/components/charts/pie-chart.vue";
import DoughnutChart from "@/components/charts/doughnut-chart.vue";
import BarChart from "@/components/charts/bar-chart.vue";

export default {
  page: {
    title: "Starter",
    meta: [{ name: "description", content: 'Application Dashboard' }],
  },
  components: {
    BarChart,
    DoughnutChart,
    PieChart,
    DataLoader,
    ExternalStatCard,
    StatCard,
    Layout,
    PageHeader,
    LineChart,
},
  data() {
    return {
      year: new Date().getFullYear(),
      user_count: 0,
      total_savings: 0,
      total_saving_fees: 0,
      total_registration_fees: 0,
      total_registration: 0,
      total_transactions: 0,
      total_members: 0,
      total_commercials: 0,
      total_supervisors: 0,
      total_coordinators: 0,
      unformated_deposits: 0,
      unformated_registration: 0,
      unformated_commercials: 0,
      unformated_members: 0,
      campost_payment_balance: "0",
      sms_credit: "0",
      url : '',
      savingPerMonthChart: {
        labels: [],
        data: []
      },
      usersGroupedBySex: {
        labels: [],
        data: []
      },
      userGroupedByCity: {
        labels: [],
        data: []
      },
      userGroupedByRegion: {
        labels: [],
        data: []
      },
      userGroupedByAge: {
        labels: [],
        data: []
      },
      showDataLoader: true
    };
  },
  mounted() {
    this.url = '/statistic/' + this.$store.getters['auth/userId'] + '?year=' + this.year
    this.getStats()
  },
  methods: {
    formatNumber(number = 0){
      return number.toLocaleString()
    },
    getStats(){
      API.get(this.url)
          .then(response => {
            if (response.status === 200) {
              const statistics = response.data
              this.user_count = this.formatNumber(statistics.users)
              this.total_savings = this.formatNumber(statistics.deposit)
              this.total_transactions = this.formatNumber(statistics.transactions)
              this.total_members = this.formatNumber(statistics.members)
              this.total_commercials = this.formatNumber(statistics.commercials)
              this.total_supervisors = this.formatNumber(statistics.supervisors)
              this.total_coordinators = this.formatNumber(statistics.coordinators)
              this.total_registration = this.formatNumber(statistics.registration)
              this.total_saving_fees = this.formatNumber(statistics.contributionNetworkFees)
              this.total_registration_fees = this.formatNumber(statistics.subscriptionNetworkFees)
              this.campost_payment_balance = statistics.campostPaymentBalance
              this.sms_credit = statistics.smsCredit
              this.unformated_deposits = statistics.deposit
              this.unformated_registration = statistics.registration
              this.unformated_commercials = statistics.commercials
              this.unformated_members = statistics.members

              const savingPerMonth = statistics.transactionsPerMonth
              const usersGroupedBySex = statistics.usersGroupedBySex
              const groupedByCities = statistics.groupedByCities
              const groupedByRegions = statistics.groupedByRegions
              const groupedByAge = statistics.groupedByAge

              for (const age in groupedByAge) {
                this.userGroupedByAge.labels.push(age)
                this.userGroupedByAge.data.push(groupedByAge[age])
              }

              for (const city in groupedByCities) {
                this.userGroupedByCity.labels.push(city)
                this.userGroupedByCity.data.push(groupedByCities[city])
              }

              for (const region in groupedByRegions) {
                this.userGroupedByRegion.labels.push(region)
                this.userGroupedByRegion.data.push(groupedByRegions[region])
              }

              for (const sex in usersGroupedBySex) {
                this.usersGroupedBySex.labels.push(sex)
                this.usersGroupedBySex.data.push(usersGroupedBySex[sex])
              }


              for (const month in savingPerMonth) {
                this.savingPerMonthChart.labels.push(month)
                this.savingPerMonthChart.data.push(savingPerMonth[month])
              }

            }
          })
          .finally(() => {
            this.showDataLoader = false
          })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader 
      :show-add-button="false" 
      :title="'Dashboard'" 
      :show-search="false"
    />
    <section>
      <div class="row">
        <div class="col-12">
          <h5 class="text-decoration-underline mb-3 pb-1">Statistiques Générales</h5>
        </div>
      </div>
      <data-loader v-if="showDataLoader"/>
      <div class="row" v-else-if="!showDataLoader">
        <StatCard
            :amount="campost_payment_balance"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'solde campost payment'"
        />
        <StatCard
          :amount="total_registration"
          :is-money="true"
          :is-increase="true"
          :percentage="65"
          :title="'Frais d\'Inscriptions'"
          :to-link="'/admin/transaction/subscription'"
        />
        <StatCard
            :amount="total_savings"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'Epargne Totale'"
            :to-link="'/admin/transaction/savings'"
        />
        <StatCard
          :title="'Frais sur insciption'"
          :to-link="'/admin/transaction/subscription'"
          :amount="total_registration_fees"
          :is-increase="false"
          :percentage="35"
          is-money
        />
        <StatCard
            :title="'Frais sur epargnes'"
            :to-link="'/admin/transaction/savings'"
            :amount="total_saving_fees"
            :is-increase="false"
            :percentage="35"
            is-money
        />
        <StatCard
            :title="'Coordinateurs'"
            :to-link="'/admin/coordinators'"
            :amount="total_coordinators"
            :is-increase="false"
            :icon-bg="'primary'"
            :percentage="35"
            :icon="'user-circle'"
        />
        <StatCard
            :title="'Superviseurs'"
            :to-link="'/admin/supervisors'"
            :amount="total_supervisors"
            :is-increase="false"
            :icon-bg="'primary'"
            :percentage="35"
            :icon="'user-circle'"
        />
        <StatCard
            :title="'Commerciaux'"
            :to-link="'/admin/commercials'"
            :amount="total_commercials"
            :is-increase="false"
            :icon-bg="'primary'"
            :percentage="35"
            :icon="'user-circle'"
        />
        <StatCard
            :title="'Adhérents'"
            :to-link="'/admin/members'"
            :amount="total_members"
            :is-increase="false"
            :icon-bg="'primary'"
            :percentage="35"
            :icon="'user-circle'"
        />
        <ExternalStatCard
          :amount="sms_credit"
          :icon="'message'"
          :title="'sms restant'"
        />
      </div>
    </section>
    <section class="row" v-if="!showDataLoader">
      <div class="col">
        <div class="row">
          <div class="col-md-4">
            <PieChart
                :labels="['Inscription', 'Epargne']"
                :data="[unformated_registration, unformated_deposits]"
                :title="'Répartition des fonds'"
            />
          </div>
          <div class="col-md-4">
            <bar-chart
                :labels="['Coordinateurs', 'Commerciaux', 'Adherents', 'Superviseurs']"
                :data="[total_coordinators, unformated_commercials, unformated_members, total_supervisors]"
                :title="'Répartition des utilisateurs par role'"
            />
          </div>
          <div class="col-md-4">
            <PieChart
                :labels="usersGroupedBySex.labels"
                :data="usersGroupedBySex.data"
                :title="'Répartition des utilisateurs par sexe'"
            />
          </div>
          <div class="col-md-4">
            <doughnut-chart
                :labels="userGroupedByAge.labels"
                :data="userGroupedByAge.data"
                :title="'Répartition des utilisateurs par age'"
            />
          </div>
          <div class="col-md-4">
            <doughnut-chart
                :labels="userGroupedByCity.labels"
                :data="userGroupedByCity.data"
                :title="'Répartition des utilisateurs par ville'"
            />
          </div>
          <div class="col-md-4">
            <doughnut-chart
                :labels="userGroupedByRegion.labels"
                :data="userGroupedByRegion.data"
                :title="'Répartition des utilisateurs par region'"
            />
          </div>
        </div>
        <LineChart
            :labels="savingPerMonthChart.labels"
            :data="savingPerMonthChart.data"
            :title="'Entrées en ' + year"
            :year="year"
        />
      </div>
    </section>
  </Layout>
</template>